<template>
  <div class="top">
    <div class="login">
      <a-spin :spinning="loading">
        <div class="container">
          <div class="input-box">
            <div class="input-size">
              <h1>合&nbsp;同&nbsp;管&nbsp;理&nbsp;系&nbsp;统</h1>
            </div>
            <div class="straight-line" />
            <div class="username-box">
              <div class="username-input">
                <img
                  class="photo"
                  src="../../assets/login/2.png"
                >
                <a-input
                  v-model="userName"
                  class="input"
                  placeholder="请输入用户名"
                  @keyup.enter="handleLogin"
                />
              </div>
              <div class="password-box">
                <div class="password-input">
                  <img
                    class="photo-password"
                    src="../../assets/login/1.png"
                  >
                  <a-input v-model="userPass" class="input" type="password" placeholder="请输入密码" @keyup.enter="handleLogin" /></div>
              </div>
              <div class="login-btn" @click="handleLogin">
                <div class="btn">登&nbsp;录</div>
              </div>
            </div>
          </div>
        </div></a-spin>
    </div>
  </div>
</template>

<script>
import adminApi from '../../api/admin'
import { setToken } from '../../utils/auth'
export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      userName: '',
      userPass: '',
      loading: false
    }
  },
  methods: {
    handleLogin() {
      if (this.loading) {
        return
      }
      this.loading = true
      adminApi.login({ loginName: this.userName, loginPass: this.userPass }).then(res => {
        if (res.status === 200) {
          setToken(res.data)
          this.$router.push({
            path: '/'
          })
        } else {
          this.$message.error(res.msg)
        }
      }).finally(() => {
        setTimeout(_ => { this.loading = false }, 1000)
      })
    }
  }
}
</script>

<style scoped>
.top{
  width: 1920px;
  height: 960px;
}
.login {
  width:  2113px;
  height: 1159px;
  background: url('../../assets/login/login-back.png');
  position: relative;
  top:-190px;
  right: 193px;
  background-repeat: no-repeat;
  background-size: 112% 119%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  width: 1800px;
  height: 660px;
  position: relative;
}
.input-box {
  padding-left: 32px;
  padding-right: 32px;
  border-radius: 20px;
  position: absolute;
  top: 220px;
  right: 50px;
   width: 600px;
  height: 365px;
  background-color: #ffffff;
  box-shadow: 0px 7px 24px 0px rgba(23, 55, 67, 0.24);
}
.input-size{
    width: 245px;
    height: 40px;
    margin: 0 auto;
    margin-top: 30px;
}
.input-size h1{
  width: 297px;
  height: 42px;
  font-size: 34px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #3C4DFD;
  line-height: 27px;
  background: linear-gradient(-4deg, #267BE4 0%, #3DDBF7 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.straight-line{
  width: 470px;
  height: 1px;
  margin: 0 auto;
  margin-top:10px;
  background: #CCCCD1;
}
.photo{
  width: 20px;
  position:absolute;
  z-index: 1;
  left: 78px;
  top:120px;
}
.username-input {
  margin-top: 30px;
  margin-left: 25px;
}
.username-input .input {
  text-indent:42px;
  letter-spacing: 2px;
  color: #010611;
  border: unset;
  width: 460px;
  height: 43px;
  background: #FFFFFF;
  border: 1px solid #0B67CC;
  opacity: 0.84;
  border-radius: 22px;
  padding-bottom: 10px;
  font-size:16px;
}
.password-input {
  margin-top: 30px;
  margin-left: 25px;
}
.photo-password{
  width: 20px;
  position:absolute;
  z-index: 1;
   left: 78px;
  top:195px;
}
.password-input input {
  text-indent:42px;
  letter-spacing: 2px;
  color: #010611;
  border: unset;
  width: 460px;
  height: 43px;
  background: #FFFFFF;
  border: 1px solid #0B67CC;
  opacity: 0.84;
  border-radius: 22px;
  padding-bottom: 10px;
  font-size:16px;
}
.password-input input:focus {
  border-bottom: 1px solid #a8bcea;
  outline: unset;
}
.username-input input:focus {
  border-bottom: 1px solid #a8bcea;
  outline: unset;
}
.btn{
  margin: 0 auto;
  width: 58px;
  height: 23px;
  font-size: 26px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #F5EFFF;
  line-height: 49px;
}
.login-btn {
  width: 464px;
  height: 54px;
  background: linear-gradient(93deg, #267BE4,#3DDBF7);
  border-radius: 19px;
  margin-top: 30px;
  margin-left: 20px;
  cursor: pointer
}
</style>
