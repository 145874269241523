import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import './permission'
import './utils/filters'
Vue.use(Antd)
import moment from 'moment'
import 'moment/locale/zh-cn'
import 'ant-design-vue/es/locale/zh_CN'
import mavonEditor from 'mavon-editor'
Vue.use(mavonEditor)
import 'mavon-editor/dist/css/index.css'
moment.locale('zh-cn')
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
