<template>
  <div class="header">
    <div class="breadcrumb">
      <a-breadcrumb>
        <a-breadcrumb-item>{{ $route.matched[0].name }}</a-breadcrumb-item>
        <a-breadcrumb-item>{{ $route.name }}</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="title">
        {{ $route.name }}
      </div>
    </div>
    <div class="right-box">
      <div class="message-box">
        <a-icon style="width:20px;height:20px;font-size:20px" type="bell" theme="filled" />
        <div style="height: 50px;">
          <a-badge count="0">
            <a href="#" class="head-example" />
          </a-badge>
        </div>
      </div>

      <div class="icon" @click="userSetting = true">
        <img :src="$store.getters.avatarUrl? '/prod-api/static' + $store.getters.avatarUrl : require('../../assets/user-icon.jpg')" @mouseenter="userSetting = true">
        <transition name="fade">
          <div v-show="userSetting" class="user-setting-box" @mouseleave="userSetting = false">
            <div class="user-name">{{ $store.getters.name }}</div>
            <div class="fenge" />
            <div class="user-center" @click="handleUserCenterView">个人中心</div>
            <div class="fenge" />
            <div class="logout-box" @click="handleLogout">退出登录</div>
          </div>
        </transition>
      </div>
      <div class="down-box" @click="userSetting = !userSetting">
        <a-icon type="down" style="width:10px;height:16px;" />
      </div>
    </div>
  </div>
</template>

<script>
import { resetRouter } from '../../router/index.js'
import { setToken } from '../../utils/auth.js'
import adminApi from '../../api/admin'
export default {
  data() {
    return {
      userSetting: false,
      breadcrumbTop: '',
      breadcrumbSecond: ''
    }
  },
  watch: {
    '$route'(val) {
    }
  },
  created() {
    adminApi.getInfo().then(res => {
      this.$store.dispatch('user/setUser', res.data)
    })
  },
  methods: {
    handleLogout() {
      this.$store.dispatch('router/setRouters', [])
      resetRouter()
      setToken('')
      this.$router.push({
        path: '/login'
      })
    },
    handleUserCenterView() {
      this.$router.push({
        path: '/user-center'
      })
    }
  }
}
</script>
<style scoped>
.header {
  width: 100%;
  height: 70px;
  box-sizing: border-box;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}
.breadcrumb {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  font-size: 18px;
  font-weight: bold;
}
.right-box {
  display: flex;
  cursor: pointer;
}
.name {
  line-height: 50px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: bold;
}
.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  user-select: none;
}
.icon img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
}
.down-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  margin-right: 10px;
}
.message-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}
.user-setting-box {
  position: absolute;
  right: -25px;
  top: 50px;
  text-align: center;
  box-shadow: 0 20px 27px rgba(0,0,0,.1);
  border: 1px solid #fafafa;
  width: 120px;
  display: flex;
  z-index: 999;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  box-sizing: border-box;
  cursor:auto;
}
.user-name {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
}
.user-center {
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
}
.fenge {
  width: 80%;
  height: 1px;
  margin-left: 10%;
  box-sizing: border-box;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-right: 10%;
  background-color: #e4e4e4;
}
.logout-box {
  font-size: 14px;
  color: #f5222d;
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  margin-bottom: 10px;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 2s
}
.fade-enter, .fade-leave-to /* .fade-leave-active, 2.1.8 版本以下 */ {
    opacity: 0
}
</style>
