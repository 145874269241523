const state = {
  name: '',
  avatarUrl: '',
  roleId: ''
}

const mutations = {
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_AVATAR_URL: (state, avatarUrl) => {
    state.avatarUrl = avatarUrl
  },
  SET_USER: (state, user) => {
    state.name = user.name
    state.roleId = user.roleId
    state.avatarUrl = user.avatarUrl
  }
}

const actions = {
  setName({ commit }, name) {
    commit('SET_NAME', name)
  },
  setAvatarUrl({ commit }, avatarUrl) {
    commit('SET_AVATAR_URL', avatarUrl)
  },
  setUser({ commit }, user) {
    commit('SET_USER', user)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
