import request from '@/utils/request'

export function login(data) {
  return request({
    url: '/admin/login',
    method: 'post',
    data
  })
}

export function getInfo() {
  return request({
    url: '/admin/info',
    method: 'get'
  })
}

export function listSimple() {
  return request({
    url: '/admin/member/simple',
    method: 'get'
  })
}

export function list(params) {
  return request({
    url: '/admin/list',
    method: 'get',
    params
  })
}

export function down(id) {
  return request({
    url: `/admin/down/${id}`,
    method: 'put'
  })
}

export function up(id) {
  return request({
    url: `/admin/up/${id}`,
    method: 'put'
  })
}

export function roleList() {
  return request({
    url: '/admin/role/list',
    method: 'get'
  })
}

export function add(data) {
  return request({
    url: '/admin',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: '/admin',
    method: 'put',
    data
  })
}

export function getRoleById(id) {
  return request({
    url: `/admin/role/${id}`,
    method: 'get'
  })
}

export function editRole(data) {
  return request({
    url: '/admin/role',
    method: 'put',
    data
  })
}

export function addRole(data) {
  return request({
    url: '/admin/role',
    method: 'post',
    data
  })
}

export function delRole(id) {
  return request({
    url: `/admin/role/${id}`,
    method: 'delete'
  })
}
const adminApi = {
  login,
  getInfo,
  listSimple,
  list,
  down,
  up,
  roleList,
  editRole,
  getRoleById,
  add,
  addRole,
  delRole,
  edit
}

export default adminApi
