<template>
  <div class="navigation">
    <div v-for="(item, index) in routerList" :key="index" class="navigation-item">
      <div :class="{'select-box': true, 'select-box-active': index === activeIndex}" @click="handleSelectItem(index)">
        <div :class="{'icon-box': true,'icon-box-active': index === activeIndex}">
          <a-icon :type="item.icon" theme="filled" />
        </div>
        <div class="title-box">
          {{ item.title }}
        </div>
        <div v-if="item.children.length > 0" class="right-icon-box">
          <a-icon v-if="index !== activeIndex" type="down" style="width:10px;height:16px;" />
          <a-icon v-if="index === activeIndex" type="up" style="width:10px;height:16px;" />
        </div>
      </div>
      <div v-if="index === activeIndex && item.children.length > 0" class="second-select-box">
        <div v-for="(item1, index1) in item.children" :key="index1" class="second-select-box-item" @click="handleSecondClick(item1)">
          <div class="second-select-box-point-box">
            <div :class="{'point': true, 'point-active': sencondActiveId === item1.id}" />
          </div>
          <div :class="{'second-title':true, 'second-title-active':sencondActiveId === item1.id }">
            {{ item1.title }}
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  data() {
    return {
      sencondActiveId: '',
      activeIndex: 0,
      routerList: [
      ]
    }
  },
  created() {
    const routerList = this.$store.getters.routers
    routerList.forEach((item, index) => {
      item.children = item.children.filter(item => item.hidden !== 1)
      if (item.path === this.$route.matched[0].path) {
        this.activeIndex = index
        item.children.forEach(item1 => {
          if (item.path + '/' + item1.path === this.$route.matched[1].path) {
            this.sencondActiveId = item1.id
          }
        })
      }
    })
    this.routerList = routerList
  },
  methods: {
    handleSelectItem(index) {
      if (this.activeIndex === index) {
        this.activeIndex = ''
      } else {
        this.activeIndex = index
      }
    },
    handleSecondClick(item) {
      this.sencondActiveId = item.id
      this.$router.push({ path: this.routerList[this.activeIndex].path + '/' + item.path })
    }
  }
}
</script>
<style scoped>
.navigation {
  margin-top: 18px;
  display: flex;
  flex-direction: column;
}
.navigation-item {
  user-select: none;
  width: 200px;
}
.select-box-active {
  background-color: #fff;
  box-shadow: 0 20px 27px rgba(0,0,0,.05);
}
.select-box {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 52px;
  height: 52px;
  display: flex;
  padding-left: 24px;
  padding-right: 24px;
  box-sizing: border-box;
  align-items: center;
  cursor: pointer;
  position: relative;
  border-radius: 8px;
  transition: all .3s ease-in-out;
}
.icon-box {
  font-size: 18px;
  width: 32px;
  height: 32px;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0,0,0,.12);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 11px;
  color: #bfbfbf;
  transition: all .3s ease-in-out;
}
.icon-box-active {
 background-color: #1890ff;
 color: white;
}
.title-box {
  color: #141414;
}
.right-icon-box {
  position: absolute;
  top: 50%;
  right: 24px;
  width: 10px;
  transition: transform .3s cubic-bezier(.645,.045,.355,1);
  transform:translateY(-50%);
  font-size: 10px;
  color: #141414;
}

.second-select-box {
  padding-left: 40px;
  box-sizing:  border-box;
  display: flex;
  flex-direction: column;
  transition: background .3s,width .3s cubic-bezier(.2,0,0,1) 0s;
  transition-property: background, width;
  transition-duration: 0.3s, 0.3s;
  transition-timing-function: ease, cubic-bezier(0.2, 0, 0, 1);
  transition-delay: 0s, 0s;
}
.second-select-box-item {
  width: 154px;
  height: 39px;
  line-height: 39px;
  display: flex;
  cursor: pointer;
}
.second-select-box-point-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
}
.point {
  width: 5px;
  height: 5px;
  background-color: #9a9eb5;
  border-radius: 15px;
}
.point-active {
  width: 7px;
  height: 7px;
  background-color: #3a416f;
}
.second-title {
  margin-left: 10px;
}
.second-title-active {
  color: #303030;
  font-weight: bold;
}
</style>
