<template>
  <div class="layer">
    <div class="left">
      <div class="title-box">
        <img class="top-icon" width="30px" height="30px" src="../assets/icon.svg">
        <span class="top-title">云涛全景图</span>
      </div>
      <div class="fenge" />
      <div class="navigation-box">
        <navigation />
      </div>
    </div>
    <div class="right">
      <Header />
      <router-view class="main-box" />
    </div>
  </div>
</template>

<script>
import Navigation from './component/Navigation.vue'
import Header from './component/Header.vue'
export default {
  components: { Navigation, Header }
}
</script>
<style scoped>
.layer{
  width: 100%;
  height: 100%;
  display: flex;
}
.left {
  padding-top: 20px;
  width: 260px;
  padding-left: 30px;
  padding-right: 30px;
  box-sizing: border-box;
  background-color: #fafafa;
}
.right {
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
}
.title-box {
  color: black;
  vertical-align: middle;
  font-size: 20px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  padding-top: 12px;
}
.top-title {
  margin-left: 10px;
  vertical-align: middle;
}
.fenge {
  width: 200px;
  margin-top: 18px;
  height: 2px;
  background-color: #e4e4e4;
}
.main-box {
  /* height: calc(100vh - 100px); */
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0 20px 27px rgba(0,0,0,.05);
  margin-top: 10px;
}

</style>
