import request from '@/utils/request'

export function list() {
  return request({
    url: '/admin/menu/list',
    method: 'get'
  })
}

export function add(data) {
  return request({
    url: '/admin/menu',
    method: 'post',
    data
  })
}

export function edit(data) {
  return request({
    url: '/admin/menu',
    method: 'put',
    data
  })
}

export function simple() {
  return request({
    url: '/admin/menu/simple',
    method: 'get'
  })
}

export function del(id) {
  return request({
    url: `/admin/menu/${id}`,
    method: 'delete'
  })
}

export function getMenu() {
  return request({
    url: `/admin/menu`,
    method: 'get'
  })
}

const menuApi = {
  list,
  simple,
  add,
  del,
  edit,
  getMenu
}

export default menuApi
