import router from './router'
import { getToken } from './utils/auth'
import store from './store'
import menuApi from './api/menu'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
NProgress.configure({ showSpinner: false })
const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist
function filterMyRoutes(routes) {
  const res = []
  routes.forEach(route => {
    const item = {
      path: route.path,
      component: routeMap[route.component],
      name: route.name,
      meta: { title: route.title }
    }
    if (route.parentId === 0) {
      item.alwaysShow = true
    }
    if (route.hidden === 1) {
      item.hidden = true
    }
    if (route.redirect && route.redirect !== '') {
      item.redirect = route.redirect
    }
    if (route.children && route.children.length !== 0) {
      item.children = filterMyRoutes(route.children)
    }
    res.push(item)
  })
  return res
}
const routeMap = {
  'layout': () => import('./layer/index.vue'),
  'roleList': () => import('./views/manager/role/Index.vue'),
  'menuList': () => import('./views/manager/menu/Index.vue'),
  'adminList': () => import('./views/manager/user/Index.vue'),
  'roleMenu': () => import('./views/manager/role/AddAuth.vue'),
  'apiList': () => import('./views/manager/myapi/Index.vue'),
  'panorama': () => import('./views/panorama/Index.vue'),
  'typeList': () => import('./views/panorama/TypeList.vue')
}
router.beforeEach(async(to, from, next) => {
  NProgress.start()
  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      // if is logged in, redirect to the home page
      next()
      NProgress.done()
    } else {
      const routers = store.getters.routers
      if (routers.length > 0) {
        next()
        NProgress.done()
      } else {
        const { data, status } = await menuApi.getMenu()
        if (status !== 200) {
          next(`/login?redirect=${to.path}`)
        }
        store.dispatch('router/setRouters', data)
        const accessedRoutes = filterMyRoutes(data)
        router.addRoutes(accessedRoutes)
        next({ ...to, replace: true })
        NProgress.done()
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // in the free login whitelist, go directly
      next()
      NProgress.done()
    } else {
      // other pages that do not have permission to access are redirected to the login page.
      next(`/login?redirect=${to.path}`)
      NProgress.done()
    }
  }
})
