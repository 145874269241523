<template>
  <a-spin :spinning="true">
    <div class="index" />
  </a-spin>
</template>

<script>
import adminApi from '../../api/admin'
export default {
  name: 'Index',
  created() {
    adminApi.getInfo().then(res => {
      this.$store.dispatch('user/setUser', res.data)
      const roleId = this.$store.getters.roleId
      if (roleId === 2) {
        this.$router.push({
          path: '/admin/index'
        })
      } else if (roleId === 1) {
        this.$router.push({
          path: '/admin/index'
        })
      }
    })
  }
}
</script>
<style scoped>
.index {
  width: 90vw;
  height: 90vh;
}
</style>
