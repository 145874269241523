const state = {
  routers: []
}

const mutations = {
  SET_ROUTERS: (state, routers) => {
    state.routers = routers
  }
}

const actions = {
  setRouters({ commit }, routers) {
    commit('SET_ROUTERS', routers)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
