<template>
  <a-locale-provider :locale="zh_CN">
    <div id="app">
      <router-view />
    </div>
  </a-locale-provider>
</template>

<script>
import zh_CN from 'ant-design-vue/es/locale/zh_CN'
export default {
  data() {
    return {
      zh_CN
    }
  }
}
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  background-color: #fafafa;
}
</style>
