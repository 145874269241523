<template>
  <div class="user-center">
    <a-spin :spinning="loading.get">
      <div class="content">
        <div class="top">
          修改个人信息
        </div>
        <a-form>
          <a-form-item label="头像" :label-col="{span:5}" :wrapper-col="{span: 16}">
            <div class="icon-container">
              <div class="icon-box">
                <img :src="editData.avatarUrl? '/prod-api/static'+editData.avatarUrl : require('../../assets/user-icon.jpg')">

              </div>
              <div class="icon-button">
                <a-upload
                  :show-upload-list="false"
                  name="file"
                  :multiple="true"
                  action="/prod-api/admin/upload/image"
                  @change="handleLicenseFileChange"
                >
                  <a-button :loading="loading.upload" size="small"> <a-icon type="upload" />修改头像</a-button>
                </a-upload>
              </div>
            </div>
          </a-form-item>
          <a-form-item label="姓名" :label-col="{span:5}" :wrapper-col="{span: 16}">
            <div>
              <a-input v-model="editData.name" />
            </div>
          </a-form-item>
          <a-form-item label="电话" :label-col="{span:5}" :wrapper-col="{span: 16}">
            <div>
              <a-input v-model="editData.phone" />
            </div>
          </a-form-item>
          <a-form-item label="邮箱" :label-col="{span:5}" :wrapper-col="{span: 16}">
            <div>
              <a-input v-model="editData.email" />
            </div>
          </a-form-item>
          <a-form-item label="登录名" :label-col="{span:5}" :wrapper-col="{span: 16}">
            <div>
              <a-input v-model="editData.loginName" :disabled="true" />
            </div>
          </a-form-item>
          <a-form-item label="密码" :label-col="{span:5}" :wrapper-col="{span: 16}">
            <div>
              <a-input-password v-model="editData.loginPass" placeholder="不输入则不改密码" />
            </div>
          </a-form-item>
        </a-form>
        <div class="bottom-box">
          <a-button style="width:100%" type="primary" @click="handleUpdateSubmit">保存修改</a-button>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import adminApi from '../../api/admin'
export default {
  data() {
    return {
      loading: {
        get: false,
        upload: false
      },
      editData: {
        avatarUrl: '',
        id: '',
        loginName: '',
        name: '',
        phone: '',
        email: ''
      },
      company: {
        name: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading.get = true
      const adminReq = adminApi.getInfo()
      adminReq.then(res => {
        this.editData = res.data
      })
      Promise.all([adminReq]).finally(_ => {
        this.loading.get = false
      })
    },
    handleLicenseFileChange(item) {
      if (item.file.status === 'uploading') {
        this.loading.upload = true
      } else if (item.file.status === 'done') {
        const myFile = item.file.response.data
        this.editData.avatarUrl = myFile.path
        this.loading.upload = false
      }
    },
    handleUpdateSubmit() {
      this.loading.get = true
      adminApi.edit(this.editData).then(res => {
        if (res.status === 200) {
          this.$message.success(res.msg)
        } else {
          this.$message.error(res.msg)
        }
      }).finally(_ => { this.loading.get = false })
    }
  }
}
</script>
<style scoped>
.user-center {
  padding: 20px;
  display: flex;
  justify-content: center;
}
.content {
  margin-top: 100px;
  width: 800px;
  box-shadow: 0 0px 10px rgba(0,0,0,.2);
  border-radius: 5px;
  padding: 20px;
}
.icon-box {
  width: 50px;
  height: 50px;
  border-radius: 40px;
  overflow: hidden;

}
.icon-box img {
  width: 100%;
  height: 100%;
  object-fit:fill;
}
.top {
  font-weight: bold;
  position: relative;
  padding-bottom: 20px;
  padding-left: 10px;
}
.top::after {
  content: "";
  position: absolute;
  height: 15px;
  width: 5px;
  background: #1890ff;
  left: 0px;
  top: 10%;
}
.icon-container {
  display: flex;
}
.icon-button {
  padding-top: 6px;
  margin-left: 10px;
}
.bottom-box {
  width: 100%;
  padding-left: 94px;
  padding-right: 94px;
  padding-top: 10px;
  padding-bottom: 10px;
}
</style>
