import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login/Login.vue'
import Layer from '../layer/index.vue'
import UserCenter from '../views/setting/UserCenter.vue'
import Index from '../views/index/index.vue'
// import Layer from '../layer/index.vue'
// import ManagerUser from '../views/manager/user/Index.vue'
// // import ManagerRole from '../views/manager/role/Index.vue'
// import ManagerRoleAddAuth from '../views/manager/role/AddAuth.vue'
// // import ManagerApi from '../views/manager/myapi/Index.vue'
// import ManagerMenu from '../views/manager/menu/Index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Index
  },
  {
    path: '/user-center',
    component: Layer,
    redirect: '/user-center/index',
    name: '用户中心',
    children: [
      {
        path: 'index',
        component: UserCenter,
        name: '修改个人信息'
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  }
]
const createRouter = () => new VueRouter({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

const router = new VueRouter({
  routes
})

export default router
